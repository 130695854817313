<style scoped>
</style>
<template>
  <div v-if="dept_Level2List.length > 0">
    <Tabs :value="active" @on-click="chooseItem">
      <TabPane
        v-for="(item, index) in dept_Level2List"
        :key="index"
        :label="item.deptName"
        :name="item.deptName"
      ></TabPane>
    </Tabs>
  </div>
</template>

<script>
import _ from "lodash";

import { getDeptLevelData } from "../api/cacheDept";

export default {
  name: "deptTabCom",
  data() {
    return {
      active: "",
      dept_Level2List: [],
    };
  },
  props: {
    // title: {
    //   type: String,
    //   default: "",
    // },
  },
  watch: {},
  computed: {},
  methods: {
    chooseItem(name) {
      const item = _.find(this.dept_Level2List, (o) => {
        return o.deptName == name;
      });
      // console.log("----", item);
      this.$emit("chooseTab", item);
    },
  },

  async created() {
    const { dept_Level2List } = await getDeptLevelData();
    this.dept_Level2List = _.reverse(_.sortBy(dept_Level2List, ["deptName"]));

    this.dept_Level2List = _.reverse(
      _.sortBy(dept_Level2List, (o) => {
        return _.indexOf(
          // -["运输站段", "机关部门", "直属单位", "非运输企业", "合资公司"],
          ["合资公司", "非运输企业", "直属单位", "机关部门", "运输站段"],
          o.deptName
        );
      })
    );

    const _curItem = this.dept_Level2List[0] || {};
    this.active = _curItem ? _curItem["deptName"] : {};
    this.$emit("chooseTab", _curItem);
  },
  watch: {
    // showModal: function (val) {
    //   this.showChooseDate = this.showModal;
    // },
  },
};
</script>
