/* eslint-disable  */
/**
 * 1.全局缓存标签
 * 2.全局缓存部门树
 *
 */

import _ from "lodash";
import moment from "moment";
import {
  getLocalStorage,
  setLocalStorage,
  wxConPost,
  statsPost,
} from "./httpApi";

/***************************************缓存部门start */
let allDept = [];
let deptLevelAnd3Obj = {};
let dept_Level2List = [];

const cacheStorageKey = "allDept";

const getDeptList = async () => {
  try {
    // 查询今天是否有数据，若有，则取今天的数据
    const cacheDeptLevelAnd3Obj = getLocalStorage(
      "deptLevelAnd3Obj",
      24 * 60 * 60 * 1000,
      true
    );
    const cacheDept_Level2List = getLocalStorage(
      "dept_Level2List",
      24 * 60 * 60 * 1000,
      true
    );
    // const cacheDeptLevelAnd3Obj = {};
    // const cacheDept_Level2List = [];
    if (
      cacheDeptLevelAnd3Obj &&
      cacheDept_Level2List &&
      !_.isEmpty(cacheDeptLevelAnd3Obj) &&
      !_.isEmpty(cacheDept_Level2List)
    ) {
      dept_Level2List = cacheDept_Level2List;
      deptLevelAnd3Obj = cacheDeptLevelAnd3Obj;
      return;
    }
    const ret = await wxConPost(
      "/services/gttt-wxstats-mgt/user/getDeptList",
      {}
    );
    deptLevelAnd3Obj = _.get(ret, ["ret"], {});
    dept_Level2List = _.get(ret, ["dept_Level2List"], []);
    setLocalStorage("deptLevelAnd3Obj", deptLevelAnd3Obj);
    setLocalStorage("dept_Level2List", dept_Level2List);
  } catch (error) {
    console.log("----", error);
  }
};

export const setAllDept = (allDept) => {
  allDept = allDept; // 全局变量缓存
  setLocalStorage(cacheStorageKey, allDept);
};

export const getAllDept = () => {
  if (allDept.length < 0) {
    allDept = getLocalStorage(cacheStorageKey);
  }
  return allDept;
};

// 缓存2级部门（排行榜等使用）
export const getDeptLevelData = async () => {
  if (_.isEmpty(dept_Level2List) || _.isEmpty(deptLevelAnd3Obj)) {
    await getDeptList();
  }
  return {
    dept_Level2List,
    deptLevelAnd3Obj,
  };
};

/***************************************缓存部门end */
/***************************************缓存标签start */

let userLabelList = [];
const getLabelList = async () => {
  try {
    //  数据缓存 10分钟 查询今天是否有数据，若有，则取今天的数据
    const _labelList = getLocalStorage("userLabelList", 10 * 60 * 1000, true);

    if (_labelList && !_.isEmpty(_labelList)) {
      userLabelList = _labelList;
      return;
    }
    const ret = await wxConPost(
      "/services/gttt-sys-setting/sysLabel/getSysLabel",
      {}
    );
    let _tmplabelList = _.get(ret, ["ret"], {});
    _tmplabelList = _.map(_tmplabelList, "name");

    userLabelList = _tmplabelList;

    setLocalStorage("userLabelList", _tmplabelList);
  } catch (error) {
    userLabelList = [];
  }
};

export const getUserLabelData = async () => {
  if (_.isEmpty(userLabelList)) {
    await getLabelList();
  }
  // userLabelList;
  return [
    {
      name: "集团网评员",
      id: "1",
    },
    {
      name: "客运",
      id: "2",
    },
  ];
};

// 查询文章标签
export const getContentLabelData = async () => {
  // return ["重磅", "热点", "原创"];
  return [
    {
      name: "重磅",
      id: "1",
    },
    {
      name: "热点",
      id: "2",
    },
    {
      name: "原创",
      id: "3",
    },
  ];
};

// 查询文章标签
export const getKeyWordLabelData = async (startDate, endDate) => {
  let _startDate = startDate;
  let _endDate = endDate;
  if (!_startDate) {
    _startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    _endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  }
  const ret = await statsPost("/label/getKeyWord", {
    startDate: _startDate,
    endDate: _endDate,
  });
  console.log("--------", ret);
  return ret.ret || ["党史学习教育", "抗击疫情", "聚焦党的二十大"];
};

let userNewLabelList = [];
let userAllLabelObj = {};
export const getNewUserLabelData = async () => {
  try {
    const _labelList = getLocalStorage("userNewLabelList", 5 * 60 * 1000, true);

    if (_labelList && !_.isEmpty(_labelList)) {
      const _labelArrLocal = getLocalStorage(
        "userNewLabelOri",
        5 * 60 * 1000,
        true
      );
      for (const iterator of _labelArrLocal) {
        userAllLabelObj[iterator.id] = iterator;
      }
      userNewLabelList = _labelList;
      return userNewLabelList;
    }
    const ret = await wxConPost(
      "/services/gttt-sys-setting/sysLabel/getAllPerson"
    );
    console.log('------111')
    console.log(ret)
    let _labelArr = _.get(ret, ["ret"], []);
    // 增加title 显示部门树
    for (const iterator of _labelArr) {
      iterator["title"] = iterator["name"];
    }

    const _level1Arr = _.groupBy(_labelArr, "order")[1];
    const _labelObj = _.groupBy(_labelArr, "parent");
    const _retArr = [];
    for (const level1 of _level1Arr) {
      level1.children = _labelObj[level1.id] || [];

      for (const level2 of level1.children) {
        level2.children = _labelObj[level2.id] || [];
      }
      if (level1.type == "label") {
        continue;
      }
      _retArr.push(level1);
    }

    for (const iterator of _labelArr) {
      userAllLabelObj[iterator.id] = iterator;
    }
    userNewLabelList = _retArr;
    // console.log("userNewLabelList------", userNewLabelList);
    setLocalStorage("userNewLabelList", _retArr);
    setLocalStorage("userNewLabelOri", _labelArr);
  } catch (error) {
    console.log("error------", error);
    userNewLabelList = [];
  }
  return userNewLabelList;
};
let contentNewLabelList = [];
let contentNewLabelObj = {};
export const getNewContentLabelData = async () => {
  try {
    const _labelList = getLocalStorage(
      "contentNewLabelList",
      5 * 60 * 1000,
      true
    );

    if (_labelList && !_.isEmpty(_labelList)) {
      const _labelArrOri = getLocalStorage(
        "contentNewLabelListOri",
        5 * 60 * 1000,
        true
      );

      for (const iterator of _labelArrOri) {
        contentNewLabelObj[iterator.id] = iterator;
      }

      contentNewLabelList = _labelList;
      console.log("-------");
      return contentNewLabelList;
    }
    const ret = await wxConPost(
      "/services/gttt-sys-setting/sysLabel/getAllArticle"
    );

    let _labelArr = _.get(ret, ["ret"], []);

    // 增加title 显示部门树
    for (const iterator of _labelArr) {
      iterator["title"] = iterator["name"];
    }

    const _level1Arr = _.groupBy(_labelArr, "order")[1];
    const _labelObj = _.groupBy(_labelArr, "parent");
    const _retArr = [];
    for (const level1 of _level1Arr) {
      level1.children = _labelObj[level1.id] || [];
      for (const level2 of level1.children) {
        level2.children = _labelObj[level2.id] || [];
      }
      if (level1.type == "label") {
        continue;
      }
      _retArr.push(level1);
    }

    for (const iterator of _labelArr) {
      contentNewLabelObj[iterator.id] = iterator;
    }
    contentNewLabelList = _retArr;
    setLocalStorage("contentNewLabelList", _retArr);
    setLocalStorage("contentNewLabelListOri", _labelArr);
  } catch (error) {
    contentNewLabelList = [];
  }
  return contentNewLabelList;
};

export const getLabelObj = async (type) => {
  if (type == "user") {
    if (_.isEmpty(userAllLabelObj)) {
      await getNewUserLabelData();
    }

    return userAllLabelObj;
  } else {
    if (_.isEmpty(contentNewLabelObj)) {
      await getNewContentLabelData();
    }
    return contentNewLabelObj;
  }
};
