<template>
  <div class="main">
    <div class="home"></div>
    <!-- <status-item v-if="userPm && userPm.pmValue"></status-item> -->
    <status-item></status-item>
    <!-- <month-data></month-data> -->
    <dept-tab-com v-show="false"></dept-tab-com>
  </div>
</template>

<script>
// @ is an alias to /src
import statusItem from "../components/statsItem.vue";
import deptTabCom from "../components/deptTabCom.vue";

export default {
  name: "Home",
  components: { statusItem, deptTabCom },
  data() {
    return {
      statsData: null,
      hisData: {},
      userPm: {},
    };
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/mine" });
    },
    toUrl(path) {
      this.$router.push({ path: path });
    },
  },
  beforeCreate() {
    document.title = "广州铁路";
  },
  async created() {},
};
</script>

<style>
.mainTop {
  text-align: center;
  color: white;
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.mainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  height: 60px;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.mainTop .box-item span {
  flex: 1;
}
.mainColor {
  background: #f9c03d;
}
.mainBgColor {
  background: #32302d;
}
.bg01 {
  background: rgb(61, 90, 151);
}
.bg02 {
  background: rgb(247, 98, 59);
}

.bg03 {
  background: #5b7498;
}
.bg04 {
  background: #ff9800;
}
.bg05 {
  background: rgb(238 134 61);
}
.bg06 {
  background: rgb(51, 124, 153);
}

/* 排序下拉加载 */
.sortContainer {
  background: #386ba1;
  color: white;
  padding: 0 20px;
  position: absolute;
  right: 8px;
  top: 50px;
  line-height: 44px;
  z-index: 999;
}

.sortContainer .show,
.sortContainer .hide {
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.sortContainer .show {
  display: block;
  max-height: 9999px;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.2s ease-in-out;
  -moz-animation: showAnimation 0.2s ease-in-out;
  -webkit-animation: showAnimation 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  -webkit-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}
.sortContainer .hide {
  max-height: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.1s ease-out;
  -moz-animation: hideAnimation 0.1s ease-out;
  -webkit-animation: hideAnimation 0.1s ease-out;
  -moz-transition: max-height 0.1s ease-out;
  -o-transition: max-height 0.1s ease-out;
  -webkit-transition: max-height 0.1s ease-out;
  transition: max-height 0.1s ease-out;
}

@keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
</style>
